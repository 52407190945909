/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, index, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            description
            author
            theme_color
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const themeColor = site.siteMetadata.theme_color
  const siteUrl = site.siteMetadata.siteUrl

  const _meta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      name: "robots",
      content: index ? "all" : "none",
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: "og:image",
      content: `${siteUrl}/image.jpg`,
    },
    {
      property: "og:url",
      content: `${siteUrl}/`,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      name: "theme-color",
      content: themeColor,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ].concat(meta)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={
        title
          ? `${title} | ${site.siteMetadata.title}`
          : site.siteMetadata.title
      }
    >
      {_meta.map((m, i) => (
        <meta {...m} key={i} />
      ))}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `de`,
  index: true,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  lang: PropTypes.string,
  index: PropTypes.bool,
  meta: PropTypes.arrayOf(PropTypes.object),
  description: PropTypes.string,
}

export default SEO
