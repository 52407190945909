/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "normalize.css"

import Header from "./header/header"
import "./layout.css"
import { Footer } from "./footer/footer"
import { OwnMDX } from "./ownMdx"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  layout: {
    [`@media (max-width: ${theme.vars.mobileWidth}px)`]: {
      "@global": {
        h1: {
          lineHeight: "53px",
          fontSize: "36px",
        },
        h6: {
          fontSize: "22px",
          lineHeight: "40px",
        },
      },
    },
  },
}))

const Layout = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <div className={classes.layout}>
      <Header
        siteTitle={props.pageContext?.frontmatter?.title}
        index={props.pageContext?.frontmatter?.index}
      />
      <main
        style={{
          minHeight: "calc(100vh - (95px + 24px + 112px))",
        }}
      >
        <div
          style={{
            padding: {
              bottom: 24,
            },
          }}
        >
          <main>
            <OwnMDX>{children}</OwnMDX>
          </main>
        </div>
      </main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
