import { HeaderLink } from "./headerLink"
import React from "react"

export const NormalHeader = ({ links, className }) => {
  return (
    <div className={className}>
      {links.map(e => (
        <HeaderLink key={e.href} {...e} />
      ))}
    </div>
  )
}
