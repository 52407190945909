import React, { useState } from "react"
import { createUseStyles } from "react-jss"
import MenuIcon from "../../images/icons/menu-24px.svg"
import CloseIcon from "../../images/icons/close-24px.svg"
import { Link } from "gatsby"

const useStyles = createUseStyles(theme => ({
  menu: {
    color: theme.palette.primary.main,
    height: 32,
    width: 32,
  },
  overlay: {
    zIndex: "1000",
    backgroundColor: theme.palette.primary.main,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "strech",
    transition: ["transform", "200ms"],
    transform: "translate(100%, 0)",
  },
  showOverlay: {
    transform: "translate(0, 0)",
    transition: ["transform", "250ms"],
  },
  item: {
    ...theme.mixin.linkReset,
    textAlign: "center",
    fontSize: 28,
    fontWeight: 800,
    padding: {
      top: 32,
      bottom: 32,
    },
    color: "black",
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.white,
    },
  },
  closeIcon: {
    position: "fixed",
    top: 31, // 24 + 7
    right: 24,
    height: 32,
    width: 32,
  },
}))

export const MobileHeader = ({ links, className }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  return (
    <div className={className}>
      <MenuIcon onClick={() => setOpen(true)} className={classes.menu} />

      {
        <div
          className={classes.overlay + (open ? " " + classes.showOverlay : "")}
        >
          <CloseIcon
            onClick={() => setOpen(false)}
            className={classes.closeIcon}
          />

          {links.map(e => (
            <Link
              onClick={() => setOpen(false)}
              className={classes.item}
              to={e.href}
              key={e.href}
            >
              {e.name}
            </Link>
          ))}
        </div>
      }
    </div>
  )
}
