import React from "react"
import { createUseStyles } from "react-jss"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const useStyles = createUseStyles(theme => ({
  root: {
    display: "inline-block",
    color: "white",
    textDecoration: "none",
    transition: ["color", "250ms"],
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}))

export const FooterLink = ({ href, name, ...props }) => {
  const classes = useStyles()

  return (
    <Link className={classes.root} to={href} {...props}>
      {name}
    </Link>
  )
}

FooterLink.propTypes = {
  href: PropTypes.string,
  name: PropTypes.string,
}
