import { MDXProvider } from "@mdx-js/react"
import React from "react"
import { MyA } from "./mdComponents/myA"

const components = {
  a: MyA,
}

export const OwnMDX = ({ children }) => (
  <MDXProvider components={components}>{children}</MDXProvider>
)
